
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`User and organizations`}</h1>
    <p>{`A `}<strong parentName="p">{`user`}</strong>{` is an individual person that logs in using an email / password combination,
and (though not mandatory) and organizational identifier (by default, this is
`}<inlineCode parentName="p">{`CTECHNOLOGY`}</inlineCode>{`, but you might use your organizational identifier in case you want
your users to have separate accounts for your organization). By default, a user
is an "owner" of one or more vehicles, and thus gets to use the native apps, and
the "private owner" view of the web app.`}</p>
    <p>{`An `}<strong parentName="p">{`organization`}</strong>{` is a collection of users that manage different vehicles together.
Examples are shipyards, motorcycle dealers, but also manufacturers of vehicles or
even parts thereof. Similarly to users, organization can have access to vehicles
with different permissions - in this case the permissions are automatically transferred
to the users who are part of the organization. Organizations come in different
shapes (dealers, maintainers, manufacturers, etc.), which all have different
services and apps, tailored to their specific needs, available.`}</p>
    <p>{`As soon as a user becomes part of an organization, he / she gets access to the
respective web apps (e.g., a user being part of a shipyard organization has access
to the "shipyard" app, or a user being part of an OEM gets access to the "OEM" app).
Note that a user can currently only belong to a single organization.`}</p>
    <h2>{`Permissions`}</h2>
    <p>{`Users can have different (default) `}<strong parentName="p">{`permissions`}</strong>{`, e.g., a user account that is
being used on rental boats might have no edit permissions for anything, while
the main account can be used to edit vehicle settings, and so on.`}</p>
    <p>{`Similarly, users can have different permissions to interact with organizations.
Someone might only be able to access different parts of the organization's
dashboard, and cannot edit anything, while another (admin) account can do
anything possible.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;